<template>
    <div>
      <breadcrumb name="Profile" title="User Info" subtitle="Permissions" @onTitleClicked="$emit('cancel')"/>

      <vx-card>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="flex items-end px-3">
                        <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2"/>
                        <span class="font-medium text-lg leading-none">Permissions</span>
                    </div>
                    <vs-divider/>
                </div>
            </div>

            <div class="block overflow-x-auto">
                <table class="w-full permissions-table">
                    <tr>
                        <th class="font-semibold text-base text-left px-3 py-2"
                            v-for="heading in permissions" :key="heading">
                            {{ heading }}
                        </th>
                    </tr>

                    <tr v-for="permission in user_permissions" :key="permission.name">
                        <td class="px-3 py-2">{{permission.name}}</td>
                        <td class="px-3 py-2">
                            <vs-checkbox class="pointer-events-none" v-model="permission.read"/>
                        </td>
                        <td class="px-3 py-2">
                            <vs-checkbox class="pointer-events-none" v-model="permission.write"/>
                        </td>
                        <td class="px-3 py-2">
                            <vs-checkbox class="pointer-events-none" v-model="permission.create"/>
                        </td>
                        <td class="px-3 py-2">
                            <vs-checkbox class="pointer-events-none" v-model="permission.delete"/>
                        </td>
                    </tr>
                </table>
            </div>

        </vx-card>
    </div>
</template>

<script>
    import Breadcrumb from "../../../components/general/breadcrumb";
    export default {
        name: 'user-permissions',
      components: {Breadcrumb},
      data() {
            return {
                permissions: ['Module', 'Read', 'Write', 'Create', 'Delete']
            };
        },
        computed: {
            user_permissions() {
                return this.$store.state.auth.permissions;
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
