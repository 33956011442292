<template>

    <div>
      <breadcrumb name="Profile" title="User Info" subtitle="View"/>

      <vx-card title="Account" class="mb-base">

            <!-- Avatar -->
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="12">
                    <div class="vx-col-2" id="avatar-col">
                        <div class="img-container mb-4">
                            <img :src="user_data.avatar" class="rounded w-full"/>
                        </div>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="11" vs-offset="1">
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">User Name</td>
                                <td class="p-4">{{ user_data.username }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Name</td>
                                <td class=" p-4">{{ user_data.name }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Email</td>
                                <td class=" p-4">{{ user_data.email }}</td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="11" vs-offset="1">
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-2">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">Status</td>
                                <td class="p-4">{{ user_data.status }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Role</td>
                                <td class=" p-4">{{ user_data.role }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold  p-4">Company</td>
                                <td class=" p-4">{{ user_data.company }}</td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="12">
                    <vs-button color="#9187F2" type="filled" @click="$emit('edit')">Edit</vs-button>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>
    import Breadcrumb from "../../../components/general/breadcrumb";
    export default {
        name: 'user-info',
      components: {Breadcrumb},
      data() {
            return {};
        },
        computed: {
            user_data() {
                return this.$store.state.auth.admin;
            }
        }
    };
</script>

<style scoped lang="scss">
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: column;
    }
    .sm-table{
        margin: auto;
        text-align: left;
        min-width: 350px;

    }
</style>
