<template>
    <div>

      <breadcrumb name="Profile" title="User Info" subtitle="Edit" @onTitleClicked="$emit('cancel')"/>

      <vx-card :title="$t('en_all.account')" class="mb-base">

            <!-- Avatar -->
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12">
                    <div class="vx-col-2" id="avatar-col">
                        <div class="img-container mb-4">
                            <vs-upload action="https://jsonplaceholder.typicode.com/posts/" :text="$t('en_all.upload_image')" single-upload="true" accept="image/*"/>
                        </div>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12"
                    class="flex">
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table>
                            <tr>
                                <td class="font-semibold p-2">User Name</td>
                                <td class="p-2">
                                    <vs-input color="#9187F2" v-model="admin.username"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">Name</td>
                                <td class="p-2">
                                    <vs-input color="#9187F2" v-model="admin.name"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">Email</td>
                                <td class="p-2">
                                    <vs-input color="#9187F2" v-model="admin.email"/>
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-offset="1"
                    class="flex">
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-2">
                        <table>
                            <tr>
                                <td class="font-semibold p-2">Status</td>
                                <td class="p-2">
                                    <vs-input color="#9187F2" v-model="admin.status"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">Role</td>
                                <td class="p-2">
                                    <vs-input color="#9187F2" v-model="admin.role"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold  p-2">Company</td>
                                <td class="p-2">
                                    <vs-input color="#9187F2" v-model="admin.company"/>
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12">
                    <vs-button color="#9187F2" class="m-4" type="filled" @click="$emit('save', admin)">Save
                    </vs-button>
                    <vs-button color="#9187F2" class="m-4" type="filled" @click="$emit('cancel')">Cancel
                    </vs-button>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>
    import Breadcrumb from "../../../components/general/breadcrumb";
    export default {
        name: 'edit-info',
      components: {Breadcrumb},
      data() {
            return {
                admin: {
                    username: this.$store.state.auth.admin.username,
                    name: this.$store.state.auth.admin.name,
                    email: this.$store.state.auth.admin.email,
                    status: this.$store.state.auth.admin.status,
                    role: this.$store.state.auth.admin.role,
                    company: this.$store.state.auth.admin.company,
                    avatar: this.$store.state.auth.admin.avatar
                }
            };
        },
        computed: {
            user_data() {
                return this.$store.state.auth.admin;
            }
        }
    };
</script>

<style scoped lang="scss">
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: column;
    }
</style>
