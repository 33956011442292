<template>
    <div>
        <user-info v-if="!editing" @edit="editing = true"/>
        <edit-info v-if="editing" @cancel="editing = false" @save="save_info"/>
        <user-permissions/>
    </div>
</template>

<script>
    import UserInfo from './user-info';
    import EditInfo from './edit-info';
    import UserPermissions from './user-permissions';

    export default {
        name: 'profile',
        components: { UserPermissions, EditInfo, UserInfo },
        data() {
            return {
                editing: false
            };
        },
        methods: {
            save_info(payload) {
                this.editing = false;
                this.$store.dispatch('auth/update_user_info', payload);
                this.notifyUser('Success', 'User Info is Updated Successfully!', 'primary');
            },
            notifyUser(title, text, color) {
                this.$vs.notify({
                    title: title,
                    text: text,
                    color: color
                });
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
